.project-card {
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin: 0 1rem;
}

.project-card p {
  list-style: none;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}

.work-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
}

.project-card ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}
.link-btn {
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
  padding: 0.6rem 2rem;
  border: none;
  outline: 1.5px solid transparent;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.3 ease;
}

.link-btn:hover {
  color: #a993fe;
  background: #000;
  outline: 1.5px solid #a993fe;
}

.project-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6751b9;
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

.contact-btn {
  text-decoration: none;
}

.project-img {
  display: flex;
  justify-content: center;
  /* dont show img on small screens */
  @media (max-width: 768px) {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.project-title {
  font-size: medium;
  margin-bottom: 10px;
}

.project-desc {
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .work-experience-card {
    margin: 0;
  }

  .work-experience-card h6 {
    font-size: 0.95rem;
  }

  .work-experience-card ul li {
    font-size: 0.75rem;
  }
}
