/* .contact-container {
  margin: 4rem 0;
  position: relative;
}

.contact-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.contact-content {
  display: flex;
  gap: 3rem;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: row-reverse;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
} */

.contact-container {
  margin: 4rem 0;
  position: relative;
}

.contact-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.contact-content {
  display: flex;
  gap: 3rem;
}

.contact-info-card {
  display: flex;
  align-items: center;
}

.contact-info-card img {
  width: 24px;
  height: 24px;
}

.contact-info-card span {
  margin-left: 0.5rem;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-info-card span {
    display: none;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}
